export default () => {
  const popup = document.querySelector(`.popup`);

  if (!popup) {
    return;
  }

  const openBtns = document.querySelectorAll(`.js-open-popup`);
  const closeBtns = popup.querySelectorAll(`.popup__close`);
  const popupContent = popup.querySelector(`.popup__content`);


  const openPopup = (e) => {
    e.preventDefault();

    popup.classList.remove(`popup--hide`);

    document.body.classList.add(`noscroll`);
  };

  const closePopup = (e) => {
    e.preventDefault();

    popup.classList.add(`popup--hide`);

    document.body.classList.remove(`noscroll`);
  };

  openBtns.forEach((btn) => {
    btn.addEventListener(`click`, openPopup);
  });

  closeBtns.forEach((btn) => {
    btn.addEventListener(`click`, closePopup);
  });

  document.addEventListener(`keyup`, (evt) => {
    if (evt.key === `Escape`) {
      closePopup(evt);
    }
  });

  // popup.addEventListener(`click`, (e) => {
  //   if (!popupContent.contains(e.target)) {
  //     closePopup(e);
  //   }
  // });
};
