import Swiper from 'swiper';

export default () => {
  const productTabs = document.querySelectorAll(`.product__tabs-link`);
  const productTabsArrows = document.querySelectorAll(`.product__tabs-pagination`);

  productTabs.forEach((tab) => {
    tab.addEventListener(`click`, (e) => {
      e.preventDefault();

      tab.classList.add(`product__tabs-link--active`);
      document.querySelector(`#${tab.dataset.tab}`).style.display = null;

      productTabs.forEach((otherTab) => {
        if (otherTab !== tab) {
          otherTab.classList.remove(`product__tabs-link--active`);
          document.querySelector(`#${otherTab.dataset.tab}`).style.display = `none`;
        }
      });
    });
  });

  productTabsArrows.forEach((tabArrow) => {
    tabArrow.addEventListener(`click`, (e) => {
      e.preventDefault();
      const data = tabArrow.dataset.show;
      const currentTabLink = document.querySelector(`[data-tab=\"${data}\"]`);
      const currentTab = document.querySelector(`#${data}`);
      const productTabs = document.querySelectorAll(`.product__tabs-link`);

      currentTabLink.classList.add(`product__tabs-link--active`);
      currentTab.style.display = null;

      productTabs.forEach((otherTab) => {
        if (otherTab !== currentTabLink) {
          otherTab.classList.remove(`product__tabs-link--active`);
          document.querySelector(`#${otherTab.dataset.tab}`).style.display = `none`;
        }
      });
    });
  });

  if (window.outerWidth <= 1300 && window.outerWidth > 767) {
    const tabsCatalog = new Swiper (`.product__similar-wrapper`, {
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: '.product .swiper-button-next',
        prevEl: '.product .swiper-button-prev'
      },
    });
  }
};
