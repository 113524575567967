import { getSiblings } from '../vendor/tools.js';
import Swiper from 'swiper';

export default () => {
  const tabsItems = document.querySelectorAll(`.tabs-catalog__item`);

  if (!tabsItems.length) {
    return;
  }

  const tabsCatalog = new Swiper (`.tabs-catalog__container`, {
    effect: `fade`,
    allowTouchMove: false,
    autoHeight: true,
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: `.js-tabs-catalog-next`,
      prevEl: `.js-tabs-catalog-prev`,
    },
    pagination: {
      el: `.tabs-catalog__nav`,
			clickable: true,
        renderBullet: function (index, className) {
          return `<div class="tabs-catalog__bullet ${className}"><span>${tabsItems[index].getAttribute(`data-title`)}</span></div>`;
        },
    },
  });

  tabsCatalog.on(`slideChange`, function () {
    const link = tabsItems[tabsCatalog.activeIndex].querySelector(`.tabs-catalog__menu-item--active .tabs-catalog__menu-link`);
    updateMoreLink(link);
  });

  const mobileMediaQuery = window.matchMedia("(max-width: 767px)");

  document.addEventListener(`click`, (e) => {
    if (e.target.closest(`.tabs-catalog__menu-link`)) {
      e.preventDefault();
      const link = e.target.closest(`.tabs-catalog__menu-link`);
      const wrap = link.closest(`.tabs-catalog__menu-item`);
      const others = getSiblings(wrap);

      others.forEach((item) => item.classList.remove(`tabs-catalog__menu-item--active`));

      if (mobileMediaQuery.matches) {
        wrap.classList.toggle(`tabs-catalog__menu-item--active`);
      } else {
        wrap.classList.add(`tabs-catalog__menu-item--active`);
      }
    }
  });

  const moreLink = document.querySelector(`.tabs-catalog__more`);
  const moreImg = moreLink.querySelector(`.tabs-catalog__more img`);
  const moreText = moreLink.querySelector(`.tabs__catalog__more-title`);

  const updateMoreLink = (link) => {
    const wrap = link.closest(`li`);
    const newHref = link.getAttribute(`href`);
    const newImgSrc = wrap.getAttribute(`data-image`);
    const newText = link.innerHTML;

    moreLink.setAttribute(`href`, newHref);
    moreImg.setAttribute(`src`, newImgSrc);
    moreText.innerHTML = newText;
  }

  document.addEventListener(`click`, (e) => {
    if (e.target.closest(`.tabs-catalog__menu a`)) {
      e.preventDefault();
      const link = e.target.closest(`.tabs-catalog__menu a`);

      updateMoreLink(link);
    }
  });
};
