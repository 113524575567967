export default () => {
  const modalOpeners = document.querySelectorAll('.js-modal-open');
  const modalClosers = document.querySelectorAll('.js-modal-close');
  const modal = document.querySelector('.modal');

  const openModal = (e) => {
    e.preventDefault();
    modal.classList.add(`modal--open`);

    document.body.style.overflow = 'hidden';

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    document.body.append(overlay);
  };

  const closeModal = (e) => {
    e.preventDefault();
    const overlay = document.querySelector('.overlay');

    modal.classList.remove(`modal--open`);

    document.body.style.overflow = 'auto';
    overlay.remove();
  };

  modalOpeners.forEach((opener) => {
    opener.addEventListener('click', openModal);
  });

  modalClosers.forEach((opener) => {
    opener.addEventListener('click', closeModal);
  });

  document.addEventListener(`keyup`, (evt) => {
    if (evt.key === `Escape`) {
      closeModal(evt);
    }
  });

  document.addEventListener(`click`, (e) => {
    const overlay = document.querySelector('.overlay');

    if (e.target === overlay) {
      closeModal(e);
    }
  });
};
