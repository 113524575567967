export default () => {
  const mainNav = document.querySelector(`.main-nav`);

  if (!mainNav) {
    return;
  }

  const container = document.querySelector(`.page-header__container`);
  const mainNavList = document.querySelector(`.page-header__main-nav .main-nav__list`);
  const mainNavItems = document.querySelectorAll(`.page-header__main-nav .main-nav__item`);
  const mobWrap = document.querySelector(`.page-header__mobile-wrap`);
  const mobList = mobWrap.querySelector(`.mobile-menu`);

  const contacts = document.querySelector(`.page-header__contacts`);
  const mobContent = document.querySelector(`.page-header__mobile-content`);


  const mediaQueryDesktop = window.matchMedia(`(max-width:1279px)`);
  const mediaQueryTablet = window.matchMedia(`(max-width:1023px)`);
  const mediaQueryMobile = window.matchMedia(`(max-width:767px)`);

  function mediaQueryChecker() {
    if (mediaQueryDesktop.matches === true) {
      document.querySelectorAll(`.main-nav__item:not(.main-nav__item--tablet)`).forEach((item) => {
        mobList.appendChild(item);
      });
    } else {
      mobList.querySelectorAll(`.main-nav__item`).forEach((item) => {
        mainNavList.appendChild(item);
      });
    }

    if (mediaQueryTablet.matches === true) {
      mobContent.appendChild(contacts);
    } else {
      container.appendChild(contacts);
    }

    if (mediaQueryMobile.matches === true) {
      mainNavItems.forEach((item) => {
        mobList.append(item);
      });
    } else {
      mobList.querySelectorAll(`.main-nav__item--tablet`).forEach((item) => {
        mainNavList.appendChild(item);
      });
    }
  }

  window.addEventListener(`DOMContentLoaded`, function () {
    mediaQueryChecker();
    mediaQueryDesktop.addListener(mediaQueryChecker);
    mediaQueryTablet.addListener(mediaQueryChecker);
    mediaQueryMobile.addListener(mediaQueryChecker);
  });

};
