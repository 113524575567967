export default () => {
  function accordeon(accordeonItems, toggleElement, toggleButton) {
    const items = document.querySelectorAll(accordeonItems);

    if (items) {
      items.forEach((item) => {
        const toggler = toggleButton ? item.querySelector(toggleButton) : item;
        const element = item.querySelector(toggleElement);

        toggler.addEventListener(`click`, (e) => {
          e.preventDefault();
          item.classList.toggle(`opened`);
          element.style.maxHeight = element.style.maxHeight ? null : `${element.scrollHeight}px`;

          items.forEach((otherItem) => {
            const otherElement = otherItem.querySelector(toggleElement);

            if (otherItem !== item) {
              otherItem.classList.remove(`opened`);
              otherElement.style.maxHeight = null;
            }
          });
        });
      });
    }
  }

  accordeon(`.faq__item`, `.faq__answear`, `.faq__toggle-btn`);
  accordeon(`.sidebar-menu__item--parent`, `.sidebar-menu__child-categories`, `.sidebar-menu__category--parent`);
  accordeon(`.price-list__item`, `.price-list__answear`, `.price-list__toggle-btn`);
};


