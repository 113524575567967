import Swiper from 'swiper';

export default () => {
  const projectsPopup = document.querySelector(`.projects-popup`);

  if (!projectsPopup) {
    return;
  }

  const previewNext = document.querySelector(`.projects-popup__preview--next`);
  const previewImgNext = previewNext.querySelector(`img`);

  const previewPrev = document.querySelector(`.projects-popup__preview--prev`);
  const previewImgPrev = previewPrev.querySelector(`img`);

  const thumbs = document.querySelectorAll(`.projects-popup__thumb`);

  const previewUpdate = (index, img) => {
    const nextImage = thumbs[index].querySelector(`img`);
    const nextImageSrc = nextImage.getAttribute(`src`);
    img.setAttribute(`src`, nextImageSrc);
  };

  const galleryThumbs = new Swiper(`.projects-popup__thumbs`, {
    spaceBetween: 13,
    threshold: 5,
    slidesPerView: `auto`,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  const galleryTop = new Swiper(`.projects-popup__top-slider`, {
    spaceBetween: 10,
    navigation: {
      nextEl: `.projects-popup__btn--next`,
      prevEl: `.projects-popup__btn--prev`,
    },
    thumbs: {
      swiper: galleryThumbs
    },
    on: {
      init: () => {
        previewUpdate(1, previewImgNext);
      },
    },
  });

  const galleryDesc = new Swiper(`.projects-popup__desc-slider`, {
    spaceBetween: 20,
    allowTouchMove: false,
    effect: `fade`,
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: galleryThumbs
    },
    breakpoints: {
      768: {
        spaceBetween: 55,
      },
    }
  });

  galleryTop.on(`slideChange`, () => {
    galleryDesc.slideTo(galleryTop.activeIndex);
    galleryThumbs.slideTo(galleryTop.activeIndex);

    if (galleryTop.activeIndex > 0 && galleryTop.activeIndex < thumbs.length - 1) {
      previewUpdate(galleryTop.activeIndex + 1, previewImgNext);
      previewUpdate(galleryTop.activeIndex - 1, previewImgPrev);

      previewPrev.classList.remove(`projects-popup__preview--disabled`);
      previewNext.classList.remove(`projects-popup__preview--disabled`);
    } else if (galleryTop.activeIndex >= thumbs.length - 1) {
      previewNext.classList.remove(`projects-popup__preview--show`);
      previewNext.classList.add(`projects-popup__preview--disabled`);
    } else if (galleryTop.activeIndex === 0) {
      previewPrev.classList.remove(`projects-popup__preview--show`);
      previewPrev.classList.add(`projects-popup__preview--disabled`);
    }
  });

  const controlBtns = document.querySelectorAll(`.projects-popup__btn`);

  const getPreviewNode = (btn) => {
    if (btn.closest(`.projects-popup__btn--next`)) {
      return previewNext;
    }

    return previewPrev;
  }

  const showPreview = (e) => {
    const previewNode = getPreviewNode(e.target);

    if (!previewNode.classList.contains(`projects-popup__preview--disabled`)) {
      previewNode.classList.add(`projects-popup__preview--show`);
    }
  };

  const hidePreview = (e) => {
    const previewNode = getPreviewNode(e.target);

    previewNode.classList.remove(`projects-popup__preview--show`);
  };

  controlBtns.forEach((btn) => {
    btn.addEventListener(`mouseenter`, showPreview, false);
    btn.addEventListener(`mouseleave`, hidePreview, false);
  });

  const getElementIndex = (node) => {
    let index = 0;

    while ((node = node.previousElementSibling)) {
      index++;
    }

    return index;
  }

  document.addEventListener(`click`, (e) => {
    if (e.target.closest(`.project-card__link`) || e.target.closest(`.project-card__image img`)) {
      galleryThumbs.update();
      galleryTop.update();
      galleryDesc.update();

      const card = e.target.closest(`.project-card`);
      const index = getElementIndex(card);

      galleryTop.slideTo(index, 0);
    }
  });
};
