import Pristine from 'pristinejs';

export default () => {
  const feedbacks = document.querySelectorAll(`.feedback-popup`);

  if (!feedbacks) {
    return;
  }

  feedbacks.forEach((feedback) => {
    const feedbackForm = feedback.querySelector(`.feedback-popup form`);
    const feedbackPristine = new Pristine(feedbackForm, {
      classTo: `feedback-popup__field`,
      errorClass: `feedback-popup__field--error`,
      successClass: `feedback-popup__field--success`,
      errorTextParent: `feedback-popup__field`,
      errorTextTag: `div`,
      errorTextClass: `feedback-popup__error`
    });

    const emailField = feedback.querySelector(`.feedback-popup input[type="email"]`);
    const successMessage = feedback.querySelector(`.feedback-popup__success`);
    const closePopup = feedback.querySelector(`.feedback-popup__success-close`);

    feedbackPristine.addValidator(emailField, (value) => {
      const rg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return rg.test(value);
    }, ``, 2, false);

    feedbackForm.addEventListener(`submit`, (e) => {
      e.preventDefault();

      let isValid = feedbackPristine.validate();

      if (isValid) {
        // feedbackForm.submit();

        // для формы обратной связи в хэдере
        if (feedback.classList.contains(`js-small-popup`)) {
          successMessage.classList.add(`feedback-popup__success--show`);
        } else {
          // временно для видимости отправки формы
          feedbackForm.submit();
        }
      }
    });

    closePopup.addEventListener(`click`, (e) => {
      e.preventDefault();

      feedback.querySelector(`.js-small-popup-content`).classList.remove(`show`);
      feedback.querySelector(`.js-small-popup-btn`).classList.remove(`active`);
      successMessage.classList.remove(`feedback-popup__success--show`);

      feedbackForm.reset();
      feedbackPristine.reset();
    });

    const nameField = feedback.querySelector(`.feedback-popup input#feedback-popup-name`);
    nameField.addEventListener(`keypress`, (event) => {
      const regex = new RegExp(`^[A-Za-zА-Яа-яЁё ]+$`);
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }

      return true;
    });
  });
};
