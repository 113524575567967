import IMask from 'imask';

export default () => {
  const element = document.querySelector(`[type="tel"]`);
  const maskOptions = {
    mask: `+{7}(000)000-00-00`
  };

  if (element) {
    IMask(element, maskOptions);
  }
};
