// libs
import smoothscroll from 'smoothscroll-polyfill';

// modules
import about from './modules/about.js';
import popup from './modules/popup.js';
import tabs from './modules/tabs.js';
import mainNav from './modules/main-nav.js';
import smallPopups from './modules/small-popups.js';
import feedbackPopup from './modules/feedback-popup.js';
import tabsCatalog from './modules/tabs-catalog.js';
import feedbackServices from './modules/feedback-services.js';
import bodyScript from './modules/body-script.js';
import accordeon from './modules/accordeon.js';
import modal from './modules/modal.js';
import projectsCalc from './modules/projects-calc.js';
import contacts from './modules/contacts.js';
import product from './modules/product.js';
import video from './modules/video.js';
import docPreview from './modules/document-preview.js';
import projectsPopup from './modules/projects-popup.js';
import telMask from './modules/tel-mask.js';
import fileUpload from './modules/file-upload.js';

// init libs
smoothscroll.polyfill();

// init modules
about();
popup();
tabs();
mainNav();
smallPopups();
feedbackPopup();
tabsCatalog();
feedbackServices();
bodyScript();
accordeon();
modal();
projectsCalc();
contacts();
product();
video();
docPreview();
projectsPopup();
telMask();
fileUpload();
