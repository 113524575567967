export const getParents = (target, selector) => {
  let elements = [];
  let elem = target;
  let ishaveselector = selector !== undefined;

  while ((elem = elem.parentElement) !== null) {
    if (elem.nodeType !== Node.ELEMENT_NODE) {
      continue;
    }

    if (!ishaveselector || elem.matches(selector)) {
      elements.push(elem);
    }
  }

  return elements;
};

export const getSiblings = (elem) => {

  let siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling
  }

  return siblings;

};

export const show = function (elem) {

  var getHeight = function () {
    elem.style.display = 'block';
    var height = elem.scrollHeight + 'px';
    elem.style.display = '';
    return height;
  };

  var height = getHeight();
  elem.classList.add('is-visible');
  elem.style.height = height;
};

export const hide = function (elem) {

  elem.style.height = elem.scrollHeight + 'px';

  window.setTimeout(function () {
    elem.style.height = '0';
  }, 1);

  elem.classList.remove('is-visible');
};

export const toggle = function (elem, timing) {
  if (elem.classList.contains('is-visible')) {
    hide(elem);
    return;
  }

  show(elem);
};
