export default () => {
  const videoSection = document.querySelector(`.about-video`);

  if (videoSection) {
    const video = videoSection.querySelector(`.about-video__video`);
    const iframe = video.querySelector(`iframe`);
    const src = iframe.src;

    video.addEventListener(`click`, function (evt) {
      evt.preventDefault();
      video.classList.add(`about-video__video--active`);
      const newSrc = `${src}&autoplay=1`;
      iframe.src = newSrc;
      iframe.setAttribute(`allow`, `autoplay`);
    });
  }
};
