export default () => {
  const filesContainer = document.querySelector(`.order-form__uploaded-files`);
  if (!filesContainer) {
    return;
  }
  const fileInput = document.querySelector(`[type="file"]`);
  // разметка svg
  const svg = `<svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.2 12.4323V3.5H10.8V12.4323C10.8 12.8455 10.6375 13.2101 10.3125 13.526C9.9875 13.842 9.6125 14 9.1875 14H2.8125C2.3875 14 2.0125 13.842 1.6875 13.526C1.3625 13.2101 1.2 12.8455 1.2 12.4323ZM12 1.16667V2.33333H0V1.16667H3L4.0125 0H7.9875L9 1.16667H12Z' fill='#C9C9C9'>
    </path>
  </svg>`;

  // работа с файлом
  const processFile = (file) => {
    const fileName = file.name;

    // создаём необходимые эл-ты
    const div = document.createElement(`div`);
    const button = document.createElement(`button`);

    // навешиваем классы
    div.classList.add(`order-form__upload-files-wrapper`);
    button.classList.add(`order-form__delete-files`);

    // добавляем svg в кнопку
    button.innerHTML = svg;

    // собираем элемент с кнопкой и название файла
    filesContainer.append(div);
    div.append(button);
    div.append(fileName);

    // навешиваем обработчик для возможности удалить файл
    button.addEventListener(`click`, () => {
      button.parentNode.remove();
    }, false);
  };

  fileInput.addEventListener(`change`, (evt) => {
    processFile(evt.target.files[0]);
  });

  const dropArea = document.querySelector(`.order-form__file-label-wrapper`);

  [`dragenter`, `dragover`, `dragleave`, `drop`].forEach((eventName) => {
    dropArea.addEventListener(eventName, preventDefaults, false);
  });

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  dropArea.addEventListener(`drop`, handleDrop, false);

  function handleDrop(e) {
    let dt = e.dataTransfer;
    let files = dt.files;

    handleFiles(files);
  }

  function handleFiles(files) {
    ([...files]).forEach(processFile);
  }
};
