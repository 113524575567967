import { getParents, getSiblings } from '../vendor/tools.js';

export const tabsToggle = (link) => {
  const tabsWrap = getParents(link, `.tabs`)[0];

  const href = link.getAttribute(`href`);

  // nav
  link.classList.add(`tabs__nav-link--active`);

  const othersTabsItems = getSiblings(link.parentNode);

  othersTabsItems.forEach((item) => {
    item.querySelector(`.tabs__nav-link`).classList.remove(`tabs__nav-link--active`);
  });

  // content
  const newActiveContentItem = tabsWrap.querySelector(href);
  const othersContentItems = getSiblings(newActiveContentItem);

  newActiveContentItem.classList.remove(`tabs__content-item--hide`);

  othersContentItems.forEach((item) => {
    item.classList.add(`tabs__content-item--hide`);
  });
}

export default () => {
  const tabsLinks = document.querySelectorAll(`.tabs__nav-link`);

  const tabsToggleCallback = (e) => {
    e.preventDefault();

    const tabsLink = e.currentTarget;

    tabsToggle(tabsLink);
  };

  tabsLinks.forEach((link) => {
    link.addEventListener(`click`, tabsToggleCallback);
  });
};
