import Selectr from 'mobius1-selectr';

export default () => {
  const calc = document.querySelector(`.projects-calc`);

  if (!calc) {
    return;
  }

  const typeConstructionSelect = new Selectr(`#type-construction`, {
    searchable: false,
  });

  const inputs = document.querySelectorAll(`.projects-calc__text-input`);

  inputs.forEach((input) => {
    input.addEventListener(`keypress`, (event) => {
      const regex = /[0-9]|\./;
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }

      return true;
    });
  });
};
