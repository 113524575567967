import Pristine from 'pristinejs';

export default () => {
  const feedback = document.querySelector(`.js-form`);

  if (!feedback) {
    return;
  }

  const feedbackForm = feedback.querySelector(`.js-form__form`);
  const feedbackPristine = new Pristine(feedbackForm, {
    classTo: `js-form__field`,
    errorClass: `js-form__field--error`,
    successClass: `js-form__field--success`,
    errorTextParent: `js-form__field`,
    errorTextTag: `div`,
    errorTextClass: `js-form__error`
  });

  const emailField = feedback.querySelector(`input[type="email"]`);

  feedbackPristine.addValidator(emailField, (value) => {
    const rg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return rg.test(value);
  }, ``, 2, false);

  feedbackForm.addEventListener(`submit`, (e) => {
    e.preventDefault();

    let isValid = feedbackPristine.validate();

    if (isValid) {
      // feedbackForm.submit();
      feedback.classList.add(`js-form--sent-active`);
    }
  });

  const nameField = feedback.querySelector(`input[name="name"]`);
  nameField.addEventListener(`keypress`, (event) => {
    const regex = new RegExp(`^[A-Za-zА-Яа-яЁё ]+$`);
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }

    return true;
  });
};
