export default () => {
  ymaps.ready(function () {
    if (document.getElementById('map')) {
      const map = new ymaps.Map("map", {
        center: [55.744774, 37.659865],
        zoom: 14
      });

      const pointer = new ymaps.Placemark([55.744774, 37.659865], {}, {
      });
      map.geoObjects.add(pointer);
    }
  });
};
