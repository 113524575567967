import { getParents } from '../vendor/tools.js';

export default () => {
  const smallPopups = document.querySelectorAll(`.js-small-popup`);

  if (!smallPopups.length) {
    return;
  }

  const togglePopup = (openBtn, content) => {
    openBtn.classList.toggle(`active`);
    content.classList.toggle(`show`);

  };

  const closePopups = () => {
    smallPopups.forEach((popup) => {
      popup.querySelector(`.js-small-popup-content`).classList.remove(`show`);
      popup.querySelector(`.js-small-popup-btn`).classList.remove(`active`);
    });
  };

  document.addEventListener(`click`, (e) => {
    if (e.target.closest(`.js-small-popup`)) {
      if (e.target.closest(`.js-small-popup-btn`)) {
        e.preventDefault();

        const wrap = getParents(e.target, `.js-small-popup`)[0];
        const btn = wrap.querySelector(`.js-small-popup-btn`);
        const content = wrap.querySelector(`.js-small-popup-content`);
        togglePopup(btn, content);
      }

    } else {
      closePopups();
    }
  });

  document.addEventListener(`keyup`, (evt) => {
    if (evt.key === `Escape`) {
      closePopups();
    }
  });
};
